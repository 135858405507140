import { Component, OnInit } from '@angular/core';
import { ImgService } from 'src/app/img.service';
import { Scroller } from 'src/app/scroll-container/scroll.service';

@Component({
  selector: 'line-up1',
  templateUrl: './line-up1.component.html',
  styleUrls: ['./line-up1.component.less']
})
export class LineUp1Component implements OnInit {
  type = 'line-up1'
  imgPath;
  textClass: string = '';
  letterClass: string = '';

  gifSettings = {
    containerClassName: 'draco-gif-source',
    angle: {
        top: true,
        right: false,
        bottom: true,
        left: true,
    }
  }
  possibleGif = [
      'draco0.gif',
      'draco1.gif',
      'draco2.gif',
  ];
  gifs = [];

  constructor(private imgService: ImgService, private scroller: Scroller) {
    this.imgPath = this.imgService.setImgPath(this.type);
    let currentHours = new Date().getHours();

    this.scroller.text$.subscribe(textClass => {
    if (currentHours >= 22 || currentHours < 8) {
      $('.artist, .category').css('filter', 'invert(1)');
    }
      this.textClass = textClass
    });
    this.scroller.letter$.subscribe(letterClass => {
      this.letterClass = letterClass
      if (currentHours >= 22 || currentHours < 8) {
        $('.artist, .category').css('filter', 'invert(1)');
      }
    });

  }

  
  ngOnInit() {
    let currentHours = new Date().getHours();

    setTimeout(() => {
      if (currentHours >= 22 || currentHours < 8) {
        $('.artist, .category').css('filter', 'invert(1)');
      }
    }, 1000);
    
    this.runAllGif();
  }

  async runAllGif() {
      let random;
      let max = 8000;
      let min = 5000;
      for (let i = 0; i < 5; i++) {
          random = Math.random() * (max - min) + min;;
          await this.throwGif(random);
      }
  }

  throwGif(ms: number) {
      return new Promise((resolve, reject) => {
          setTimeout(() => {
              let path = '../../assets/img/gif/';
              this.gifs.push(path + this.possibleGif[Math.floor(Math.random() * this.possibleGif.length)]);
              resolve();
          }, ms);
      });
  }
}
