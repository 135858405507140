import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ScrollContainerComponent} from './scroll-container/scroll-container.component';
import { BoxOfficeComponent } from './box-office/box-office.component';
import { AdminConnectionComponent } from './admin/connection/admin-connection.component';
import { AdminLoggedComponent } from './admin/logged/admin-logged.component';
import { AuthGuard } from './admin/auth.guard';

const routes: Routes = [
  { path: 'box-office', component: BoxOfficeComponent },
  { path: 'box-office/:id', component: BoxOfficeComponent },
  { path: 'admin', component: AdminConnectionComponent },
  { path: 'admin/logged', canActivate: [AuthGuard], component: AdminLoggedComponent },
  { path: '', component: ScrollContainerComponent, pathMatch: 'full' },
  { path: '**', component: ScrollContainerComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
