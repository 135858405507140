import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bonus2',
  templateUrl: './bonus2.component.html',
  styleUrls: ['./bonus2.component.less']
})
export class Bonus2Component implements OnInit {
  imgPath = '../../assets/img/bonus2.jpg';

  constructor() { }

  ngOnInit() {
  }

}
