import { Component, OnInit } from '@angular/core';
import { ImgService } from 'src/app/img.service';
import { Scroller } from 'src/app/scroll-container/scroll.service';
import { Router } from '@angular/router';

@Component({
    selector: 'box-office-wrapper',
    templateUrl: './box-office-wrapper.component.html',
    styleUrls: ['./box-office-wrapper.component.less']
})
export class BoxOfficeWrapperComponent implements OnInit{
    type = 'box-office'
    imgPath;
    textClass;
    showTee: boolean = false;
    showPoster: boolean = false;

    gifSettings = {
      containerClassName: 'bestiole-gif-source',
      angle: {
          top: false,
          right: false,
          bottom: true,
          left: true
      }
    }
    possibleGif = [
        'bestiole0.gif',
        'bestiole1.gif',
        'bestiole2.gif',
    ];
    gifs = [];
    
    constructor(private imgService: ImgService, private scroller: Scroller,
      private router: Router) {
      this.imgPath = this.imgService.setImgPath(this.type);
      this.scroller.text$.subscribe(textClass => this.textClass = textClass);
    }

    linkToBoxOffice(index) {
      this.scroller.disable = true;
      this.router.navigateByUrl('/box-office/' + index);
    }

    ngOnInit() {
        $(document).mousemove((e) => {
            if (this.scroller.scroll)
                $(".image-tee").css({left: 10 + e.pageX + -this.scroller.scroll.x, top: 10 + e.pageY + -this.scroller.scroll.y});
            else
                $(".image-tee").css({left: 10 + e.pageX, top: 10 + e.pageY});
        });
        $(document).mousemove((e) => {
            if (this.scroller.scroll)
                $(".image-poster").css({left: 10 + e.pageX + -this.scroller.scroll.x, top: 10 + e.pageY + -this.scroller.scroll.y});
            else
                $(".image-poster").css({left: 10 + e.pageX, top: 10 + e.pageY});
        });

      this.runAllGif();
    }

    async runAllGif() {
        let random;
        let max = 8000;
        let min = 5000;
        for (let i = 0; i < 5; i++) {
            random = Math.random() * (max - min) + min;;
            await this.throwGif(random);
        }
    }

    throwGif(ms: number) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let path = '../../assets/img/gif/';
                this.gifs.push(path + this.possibleGif[Math.floor(Math.random() * this.possibleGif.length)]);
                resolve();
            }, ms);
        });
    }
}