import { Directive, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { ImgService } from './img.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[animateGif]'
})
export class AnimateGifDirective implements OnInit, OnDestroy{
  @Input() type:string = 'default';
  @Input() settings: {
    containerClassName: string,
    angle: {
      top: boolean,
      right: boolean,
      bottom: boolean,
      left: boolean
    }
  }

  topMove =  {
    min: 0,
    max: 0,
    step: 0,
    current: 0
  };

  leftMove =  {
    min: 0,
    max: 0,
    step: 0,
    current: 0
  };

  initalOffsetTop: number = 0;
  initalOffsetLeft: number = 0;
  doc = {
    width: 0,
    height: 0
  }

  subs: Array<Subscription> = [];
  intervalID;

  set top(value) {
    this.topMove.current = value;
    this.e.nativeElement.style.top = `${value}px`;
  }

  set left(value) {
    this.leftMove.current = value;
    this.e.nativeElement.style.left = `${value}px`;
  }

  constructor(private e: ElementRef, private imgService: ImgService) {
    this.subs.push(this.imgService.imageSize$.subscribe((data) => {
      this.setDocHeight(data);
    }));
    this.setDocHeight(this.imgService.getImgProperty());

  }

  ngOnInit() {
    this.setGifDirection(this.settings.angle);
    this.setInitialOffset();
    this.rotateScarabe();
    let currentHours = new Date().getHours();
    if (currentHours >= 22 || currentHours < 8) {
      this.e.nativeElement.style.filter = 'invert(1)';
    }

    setInterval(() => {
      this.top = this.topMove.current += this.topMove.step;
      this.left = this.leftMove.current += this.leftMove.step;
      this.onEdge();
    }, 100);
  }

  setInitialOffset() {
    this.initalOffsetTop = $(`.${this.settings.containerClassName}`).get(0)['offsetTop'];
    this.initalOffsetLeft = $(`.${this.settings.containerClassName}`).get(0)['offsetLeft'];
  }

  setDocHeight(data) {
    this.doc.width = data.size.width;
    this.doc.height = data.size.height * 6 + $('.partner-img').height();
  }

  setGifDirection(angle) {
    this.topMove.min = angle.top ? -30 : 0;
    this.topMove.max = angle.bottom ? 30 : 0;

    this.leftMove.min = angle.left ? -30 : 0;
    this.leftMove.max = angle.right ? 30 : 0;

    this.leftMove.step = Math.floor(Math.random() * (this.leftMove.max - this.leftMove.min) + this.leftMove.min);
    this.topMove.step = Math.floor(Math.random() * (this.topMove.max - this.topMove.min) + this.topMove.min);
  }

  onEdge() {
    if (this.leftMove.step < 0 && this.leftMove.current + 100 <= 0 - this.initalOffsetLeft) {
      this.setGifDirection({left: false, top: true, bottom: true, right: true});
      this.rotateScarabe();
    } else if (this.leftMove.current + this.e.nativeElement.offsetWidth >= this.doc.width - this.initalOffsetLeft) {
      this.setGifDirection({left: true, top: true, bottom: true, right: false});
      this.rotateScarabe();
    } else if (this.topMove.step < 0 && this.topMove.current + 100 < 0 - this.initalOffsetTop) {
      this.setGifDirection({left: true, top: false, bottom: true, right: true});
      this.rotateScarabe();
    } else if (this.topMove.current + this.e.nativeElement.offsetHeight >= this.doc.height - this.initalOffsetTop) {
      this.setGifDirection({left: true, top: true, bottom: false, right: true});
      this.rotateScarabe();
    }
  }

  rotateScarabe() {
    if (this.type === 'scarabe') {
      let adjacent = -this.topMove.step; // on inverse parce que sur un repere orthonorme le y vers le haut est positif, pas en 'html';
      let oppose = this.leftMove.step;

      let radians = Math.atan(oppose / adjacent);
      let degree = radians * (180 / Math.PI);
      
      //Si la direction du scarabe va vers le haut, alors on inverse les degres;
      // pourquoi ? je sais pas trop
      if (this.topMove.step < 0)
        degree -= 180;
      this.e.nativeElement.style.transform = `rotate(${degree}deg)`;
    }
  }

  ngOnDestroy() {
    clearInterval(this.intervalID);
    for (let sub of this.subs) {
      sub.unsubscribe();
    }
  }
}