import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { BoxOfficeService } from 'src/app/box-office/box-office.service';

@Component({
  selector: 'paypal-info',
  templateUrl: './paypal-info.component.html',
  styleUrls: ['./paypal-info.component.less']
})
export class PaypalInfoComponent implements OnInit {

  @Input() orderID;
  @Output() close = new EventEmitter<any>();

  order = undefined;
  tickets = undefined;
  constructor(private boxOfficeService: BoxOfficeService) { }

  ngOnInit() {
    this.loadTransaction();
  }

  async loadTransaction() {
    this.boxOfficeService.getTicket(this.orderID).then(result => {
      this.tickets = result;
    })   
    const result = await this.boxOfficeService.getTransaction(this.orderID);
    this.order = result[0];
  }

  @HostListener("window:keydown", ['$event'])
  onKeyDown(event:KeyboardEvent) {
    if(event.key === 'Escape')
      this.closePaypalInfo();
  }

  closePaypalInfo() {
    this.close.emit();
  }
}
