import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  pageLoadedSource = new Subject();

  pageLoaded$ = this.pageLoadedSource.asObservable();
  private imgLoad: Array<String> = [];
  private totalImg = 7;

  constructor(private router: Router) {
  }

  pageLoaded(bool: boolean) {
    this.pageLoadedSource.next(bool);
  }

  imgLoaded(type: string) {
    this.imgLoad.push(type);
    if (this.imgLoad.length === this.totalImg)
      this.pageLoaded(true);
  }

}
