import { Injectable, Renderer2, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import * as Bowser from 'bowser';

@Injectable({
    providedIn: 'root',
})
export class ImgService {

    private imageSizeSource =
        new Subject<{
            size: {
                width: number,
                height: number
            },
            format: string
        }>();

    imageSize$ = this.imageSizeSource.asObservable();
    property: {
        size: {
            width: number,
            height: number
        },
        format: string
    } = {
        size: {
            width: 0,
            height: 0
        },
        format: null
    }
    browser;

    constructor() {
        this.browser = Bowser.getParser(window.navigator.userAgent).getBrowser();
        console.log(this.browser.name);    
        console.log(this.browser.version);    
    }

    setImgPath(type) {
        const winHeight = $(window).height();
        const winWidth = $(window).width();
        let path = '../../assets/img/'
        let format;


        if (this.browser.name.search('Safari') !== -1 ||
            this.browser.name.search('Microsoft Edge') !== -1 ||
            this.browser.name.search('Internet Explorer') !== -1 ||
            (this.browser.name.search('Firefox') !== -1 && parseInt(this.browser.version) < 65))
        {
            format = 'png'; 
        } else {
            format = 'webp';
        }

        if (winHeight < winWidth) {
            return `${path}${format}/desktop/${type}.${format}`;
        } else {
            return `${path}${format}/mobile/${type}.${format}`;
        }
    }
    
    getScrollBarWidth () {
        var $outer = $('<div>').css({visibility: 'hidden', width: 100, overflow: 'scroll'}).appendTo('body'),
            widthWithScroll = $('<div>').css({width: '100%'}).appendTo($outer).outerWidth();
        $outer.remove();
        return 100 - widthWithScroll;
    };

    setImgSize(HTMLElement) {
        const winHeight = $(window).height();
        const winWidth = $(window).width() + this.getScrollBarWidth();
        const elem = HTMLElement;

        if (winHeight < winWidth) {
            this.property.size.width = winWidth;
            this.property.size.height = elem.naturalHeight * winWidth / elem.naturalWidth;
            this.property.format = 'horizontal';
        } else {
            this.property.size.width = elem.naturalWidth * winHeight / elem.naturalHeight;
            this.property.size.height = winHeight;
            this.property.format = 'vertical';
        }
        this.imageSizeSource.next(this.property);
    }

    getImgProperty() {
        return this.property;
    }
}