import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}
  
  canActivate() {
    return this.checkLogin();
  }

  async checkLogin() {
    if (await this.authService.isLogged()) {
      return true;
    }
    else {
      this.router.navigateByUrl('/');
      return false;
    }
  }
  
}
