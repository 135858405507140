import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BasicContainerComponent } from './basic-container/basic-container.component';
import { MenuComponent } from './menu/menu.component';

import { HomePageComponent } from './homepage/homepage.component';
import { BoxOfficeWrapperComponent } from './box-office/box-office-wrapper/box-office-wrapper.component';
import { BoxOfficeComponent } from './box-office/box-office.component';

import { ScrollContainerComponent } from './scroll-container/scroll-container.component'
import { ImgDirective } from './img.directive';

import { AdminConnectionComponent } from './admin/connection/admin-connection.component';
import { AdminLoggedComponent } from './admin/logged/admin-logged.component';

import { NgxPayPalModule } from 'ngx-paypal';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { PaypalInfoComponent } from './admin/logged/paypal-info/paypal-info.component';
import { LoaderComponent } from './loader/loader.component';
import { Info1Component } from './info/info1/info1.component';
import { Info2Component } from './info/info2/info2.component';
import { LineUp1Component } from './line_up/line-up1/line-up1.component';
import { LineUp2Component } from './line_up/line-up2/line-up2.component';
import { Bonus1Component } from './bonus/bonus1/bonus1.component';
import { Bonus2Component } from './bonus/bonus2/bonus2.component';
import { PartnerComponent } from './partner/partner.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AnimateGifDirective } from './animate-gif.directive';

@NgModule({
  declarations: [
    AppComponent,
    BasicContainerComponent,
    HomePageComponent,
    MenuComponent,
    BoxOfficeWrapperComponent,
    ScrollContainerComponent,
    BoxOfficeComponent,
    ImgDirective,
    AdminConnectionComponent,
    AdminLoggedComponent,
    PaypalInfoComponent,
    LoaderComponent,
    Info1Component,
    Info2Component,
    LineUp1Component,
    LineUp2Component,
    Bonus1Component,
    Bonus2Component,
    PartnerComponent,
    AnimateGifDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxPayPalModule,
    ReactiveFormsModule,
    HttpClientModule,
    Ng2SmartTableModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
