import { Component } from '@angular/core';
import { ImgService } from 'src/app/img.service';
import { Scroller } from 'src/app/scroll-container/scroll.service';

@Component({
  selector: 'line-up2',
  templateUrl: './line-up2.component.html',
  styleUrls: ['./line-up2.component.less']
})
export class LineUp2Component {
  type = 'line-up2'
  imgPath;
  textClass;

  constructor(private imgService: ImgService, private scroller: Scroller) {
    this.imgPath = this.imgService.setImgPath(this.type);
    this.scroller.text$.subscribe((textClass) => {
      this.textClass = textClass;
      if (textClass === 'desktop') {
        setTimeout(() => {
          $('line-up2 .text-container').height(this.imgService.getImgProperty().size.height);
        }, 100);
      }
    });
  }


}
