import { Injectable } from '@angular/core';
import BScroll from 'better-scroll';
import { ImgService } from '../img.service';
import { Subject, Subscription, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { LoaderService } from '../loader/loader.service';

@Injectable({
    providedIn: 'root'
})
export class Scroller {
    scroll: BScroll;
    scrollConfig = {
        freeScroll: true,
        scrollX: true,
        // scrollY: true,
        bounce: false,
        mouseWheel: true,
        swipeTime: 1000,
        probeType: 2,
        click: true,
        // dblclick: true,
        zoom: true
    };
    scrollXMarker: number = undefined;
    subscriptions: Array<Subscription> = [];

    coord: {
        initial: {
            x: number,
            y: number
        },
        current: {
            x: number,
            y: number
        }
    } = {initial: {x: 0, y: 0}, current: {x: 0, y: 0}}
    disable: boolean = false;

    textSource = new Subject<string>();
    text$ = this.textSource.asObservable();

    letterSource = new Subject<string>();
    letter$ = this.letterSource.asObservable();

    previousRoute: string = '';


    constructor(private imgService: ImgService, private router: Router, private loader: LoaderService) {
            this.subscriptions.push(imgService.imageSize$.subscribe((property) => {
                this.loadScroller(property);
            }));

            this.router.events.pipe(
                filter(event => event instanceof NavigationEnd)
              ).subscribe((event) => {
                if (event['url'] === '/') {
                    if (this.previousRoute !== '') {
                        this.loader.pageLoaded(false);
                        setTimeout(() => {
                            // this.setScrollStartY($(".homepage-img").get(0));
                            this.loadScroller(this.imgService.getImgProperty());
                            // this.scroll.scrollTo(0, 0, 0, 'swipe');
                            this.disable = false;
                            // this.scroll.on('scrollEnd', () => this.onScrollEnd());
                            // console.log(this.scroll.y);
                            // this.scroll.scrollTo(this.scrollXMarker + 100, 0, 0, 'swipe');
                            this.loader.pageLoaded(true);
                        }, 1500);
                    }
                    // this.disable = false;
                }
                // else {
                    // this.disable = true;
                // }
                this.previousRoute = event['url'];
            })
    }

    loadScroller(property) {
        if (this.scrollXMarker !== undefined) {
            this.setScrollXMarker(property);
        }
        if (this.scrollXMarker === undefined) {
            this.initScroll(property);
        }
        this.setScrollContainerWidth(property.size.width);
        this.setTextContainer(property);
    }


    setScrollXMarker(property) {
        const windowWidth = $(window).width();

        //set la valeur ou le scroll retournera si on sort du rail.
        if (property.format === 'vertical')
            this.scrollXMarker = -((property.size.width - windowWidth) / 2);
        else
            this.scrollXMarker = 0;

        //Lorsque on resize la page, place l'utilisateur bien au centre de l'image.
        if (this.scroll)
            this.onScrollEnd();
    }

    setScrollContainerWidth(width) {
        $('.container-scroll').width(width);
    }
    
    //Ajuste la  hauteur des text container
    //et la largeur des text container wrapper
    //pour que le margin auto des text containers les places bien au milieu de lecran
    setTextContainer(property) {
        $('.text-container-wrapper').width(property.size.width);
        $('homepage .text-container').height(property.size.height);
        // const lastMargin;


        const text = $(".text-container-2");
        let textOverlap = false;
        if ($(window).width() < 1070)
            textOverlap = true;

        if ($(window).height() < 560 && $(window).width() < 900)
            this.letterSource.next('letter-mobile-mini')
        else if ($(window).width() < 900)
            this.letterSource.next('letter-mobile')
        else
            this.letterSource.next('letter-desktop');

        // console.log($(window).width());

        let getTextHeight = () => {
            const imgHeight = this.imgService.getImgProperty().size.height;
            const textHeight = [imgHeight];
            let totalHeight = imgHeight;
            let pageHeight = imgHeight * 7;

            text.each(function(index) {
                let height = $(this).height();
                textHeight.push(height);
                totalHeight += height;
            });
            let marginBetweenText = (pageHeight - totalHeight) / 5;
            // console.log(textHeight);
            // console.log(marginBetweenText);

            // Marge du bas 
                marginBetweenText -= 20;
            return {height: textHeight, total: totalHeight, margin: marginBetweenText};
        }
       
        if (textOverlap) {
            this.textSource.next('mobile');
            setTimeout(() => {
                const textContainer = $('.text-container-wrapper');
                let text = getTextHeight();
                let top = 0;

                textContainer.each(function(index) {
                    if (index > 0)
                        top += text.height[index - 1];
                    if (index > 1) {
                        if (text.margin > 0)
                            top += text.margin;
                    }
                    $(this).css('top', top);
                });
            }, 100);
        } else {
            this.textSource.next('desktop');
        }        
    }

    //Set scroll position at the beginning
    setScrollStartY(elem) {
        // console.log(elem);
        const position = $(elem).position();
        this.scrollConfig['startY'] = -position.top;
        this.scroll = new BScroll('.wrapper', this.scrollConfig);

        const triggerScrollEnd = () => {
            this.onScrollEnd();
        }
        this.scroll.on('scrollEnd', triggerScrollEnd);

        // const hammer = new Hammer($('scroll-container').get(0));

        // hammer.get('pinch').set({
        //     enable: true
        //   });
  
        // hammer.on('pinch', (e) => {
        //     console.log(e);
        // });
        // this.scroll.on('scroll', () => this.onScroll());
        // this.scroll.on('scrollStart', () => this.onBeforeScrollStart());
    }

    initScroll(property) {
        this.setScrollXMarker(property);

        this.scrollConfig['startX'] = this.scrollXMarker;
    }

    onScrollEnd() {
        if (this.disable === false && this.scroll.x !== this.scrollXMarker) {
            this.scroll.scrollTo(this.scrollXMarker, this.scroll.y, 500, 'swipe');
        }
    }

    onScroll() {
        // if (this.scroll.x !== this.scrollXMarker) {
        //     this.scroll.x = this.scrollXMarker;
        // }
    }

    onBeforeScrollStart() {
        this.coord.initial.x = this.scroll.x;
        this.coord.initial.y = this.scroll.y;
    }

    //elem est un element jquery
    scrollToElement(elem) {
        const position = elem.position();
        this.scroll.scrollTo(this.scrollXMarker, -position.top, 2000, 'swipe');
    }
}

