import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';

@Component({
  selector: 'admin-connection',
  templateUrl: './admin-connection.component.html',
  styleUrls: ['./admin-connection.component.less']
})
export class AdminConnectionComponent implements OnInit {
  
  constructor(private authService: AuthService,
    private router: Router,
    private loaderService: LoaderService) { }

  ngOnInit() {
    this.loaderService.pageLoaded(true);
    this.authService.isLogged().then(result => {
      if (result)
        this.router.navigateByUrl('/admin/logged')
    });
  }


  onClick(password) {
    this.authService.login(password)
      .then(result => {
        if (result)
          this.router.navigateByUrl('/admin/logged')
      })
  }
}
