import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class BoxOfficeService {

    constructor(private http: HttpClient) { }

    saveTransaction(tickets, transaction) {
        return this.http.post('/api/transaction', {tickets, transaction})
            .toPromise()
            .then(res => res)
            .catch(this.handleError);
    }

    getTransaction(trancactionID) {
        return this.http.get(`/api/transaction/${trancactionID}`)
            .toPromise()
            .then(res => res)
            .catch(this.handleError);
    }

    getTickets(): Promise<any> {
        return this.http.get('/api/ticket')
            .toPromise()
            .then(res => res)
            .catch(this.handleError);
    }

    getTicket(orderID): Promise<any> {
        return this.http.get(`/api/ticket/${orderID}`)
            .toPromise()
            .then(res => res)
            .catch(this.handleError);
    }

    getTicketsTotal(): Promise<any> {
        return this.http.get('/api/ticket/total')
            .toPromise()
            .then(res => res)
            .catch(this.handleError);
    }

    isBoxOfficeOpen() {
        return this.http.get('/api/isBoxOfficeOpen')
            .toPromise()
            .catch(this.handleError);
    }

    getMail() {
        return this.http.get('/api/ticket/getMail')
            .toPromise()
            .catch(this.handleError);
    }

    handleError(res) {
        console.log(res.error);
    };
}