import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormArray, Validators } from '@angular/forms';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { BoxOfficeService } from './box-office.service';
import { LoaderService } from '../loader/loader.service';
import { ActivatedRoute } from '@angular/router';
import { Scroller } from '../scroll-container/scroll.service';
import { ImgService } from '../img.service';

@Component({
    selector: 'box-office',
    templateUrl: 'box-office.component.html',
    styleUrls: ['box-office.component.less']
})

export class BoxOfficeComponent implements OnInit {
    form;
    prices = [
        {id: 0, name: 'Pass 3 jours', value: 50},
        {id: 1, name: 'Pass 3 jours + tee-shirt', value: 70},
        {id: 2, name: 'Pass 3 jours + tee-shirt + affiche', value: 80},
        {id: 3, name: 'Pass 3 jours + tee-shirt + affiche + ♥', value: 100},
    ];
    public payPalConfig ? : IPayPalConfig;
    feedback: string;
    imgPath: string = '';
    img = {
        height: 0,
        width: window.innerWidth
    }
    isBoxOfficeOpen: boolean = null;

    constructor(private fb: FormBuilder,
        private boxOfficeService: BoxOfficeService,
        private loaderService: LoaderService,
        private route: ActivatedRoute,
        private imgService: ImgService) {
        this.route.params.subscribe(params => {
            this.form = this.fb.group({
                tickets: this.fb.array([this.createTicket(params.id && params.id < 4 && params.id > -1? params.id : 1)])
            });
        });
        this.imgPath = this.imgService.setImgPath('fond1');
        this.boxOfficeService.isBoxOfficeOpen().then((res) => {
            this.isBoxOfficeOpen = res['isOpen'];
        });
    }

    ngOnInit() {
        this.loaderService.pageLoaded(false);
        this.initPaypalConfig();
        setTimeout(() => {
            this.resizeImg();
            $(window).scrollLeft(0).scrollTop(0);
            this.loaderService.pageLoaded(true);
            let currentHours = new Date().getHours();
                if (currentHours >= 22 || currentHours < 8) {
                  $('img, .text, h2, .content').css('filter', 'invert(1)');
            }
        }, 1500);
    }

    resizeImg() {
        setTimeout(() => {
            this.img.height = $('.wrapper').height();
        }, 0);  
        this.img.width = window.innerWidth;
    }

    createTicket(paramID) {
        return this.fb.group({
            surname: ['', [Validators.required]],
            name: ['', [Validators.required]],
            mail: ['', [Validators.required, Validators.email]],
            price: [this.prices[paramID], [Validators.required]]
        });
    }

    addTicket() {
        this.tickets.push(this.createTicket(0));

        this.resizeImg()
    }

    removeTicket(index) {
        this.tickets.removeAt(index);
        this.resizeImg()
    }

    get tickets() {
        return this.form.get('tickets') as FormArray;
    }

    onSelectChange(event) {
        // console.log(event.target.value);
    }

    private initPaypalConfig(): void {
        this.payPalConfig = {
            clientId: 'AaaCaGhPKcMM4YnEKoQR7couOGm-ph5HU9kH_NSUa_db2MnSZskhKJaYfWfKfYW9WYF8Je3eKnBY9--G',
            // clientId: 'sb',
            currency: 'EUR',

            // for creating orders (transactions) on server see
            // https://developer.paypal.com/docs/checkout/reference/server-integration/set-up-transaction/
            createOrderOnServer: () => {
                console.log(this.tickets);
                if (this.form.status === 'INVALID')
                    return new Promise(resolve => resolve('error'));
                return fetch('/api/createOrder', {
                    method: "post",
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      data: this.tickets.value
                    }) 
                })
                .then(res => res.json())
                .then(order => {
                    console.log(order);
                    if (order.error)
                        this.feedback = order.error;
                    return order.orderID});
            },
            onApprove: (data, actions) => {
                // console.log('onApprove - transaction was approved, but not authorized', data, actions);
                actions.order.get().then(details => {
                    // console.log('onApprove - you can get full order details inside onApprove: ', details);
                });

            },
            onClientAuthorization: (order) => {
                // console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', order);
                this.boxOfficeService.saveTransaction(this.tickets.value, order)
                    .then(() => {
                        this.feedback = 'Votre ticket a été enregistré, vous recevrez un mail dans quelques instants.';
                    });
            },
            onCancel: (data, actions) => {
                // console.log('OnCancel', data, actions);
            },
            onError: err => {
                // console.log('OnError', err);
            },
            onClick: () => {
            },
        };
    }
}