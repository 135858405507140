import { Component } from '@angular/core';

@Component({
    selector: 'basic-container',
    templateUrl: './basic-container.component.html',
    styleUrls: ['./basic-container.component.less']
})
export class BasicContainerComponent {
}

