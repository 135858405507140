import { Component, OnInit } from '@angular/core';
import { ImgService } from 'src/app/img.service';
import { Scroller } from 'src/app/scroll-container/scroll.service';

@Component({
  selector: 'info1',
  templateUrl: './info1.component.html',
  styleUrls: ['./info1.component.less']
})
export class Info1Component implements OnInit {
  type = 'info1'
  imgPath;
  textClass;

  gifSettings = {
    containerClassName: 'poil-gif-source',
    angle: {
        top: true,
        right: true,
        bottom: true,
        left: false
    }
  }
  possibleGif = [
      'poil0.gif',
      'poil1.gif',
      'poil2.gif',
      'poil3.gif',
  ];
  gifs = [];

  constructor(private imgService: ImgService, private scroller: Scroller) {
    this.imgPath = this.imgService.setImgPath(this.type);
    this.scroller.text$.subscribe(textClass => this.textClass = textClass);
  }

  ngOnInit() {
    this.runAllGif();
  }

  async runAllGif() {
      let random;
      let max = 8000;
      let min = 5000;
      for (let i = 0; i < 5; i++) {
          random = Math.random() * (max - min) + min;;
          await this.throwGif(random);
      }
  }

  throwGif(ms: number) {
      return new Promise((resolve, reject) => {
          setTimeout(() => {
              let path = '../../assets/img/gif/';
              this.gifs.push(path + this.possibleGif[Math.floor(Math.random() * this.possibleGif.length)]);
              resolve();
          }, ms);
      });
  }

}
