import { Component, OnDestroy, OnInit } from '@angular/core';
import { ImgService } from '../img.service';
import { Scroller } from '../scroll-container/scroll.service';
import { LoaderService } from '../loader/loader.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.less']
})
export class HomePageComponent implements OnDestroy, OnInit {
    type = 'homepage'
    imgPath;
    subs: Array<Subscription> = [];
    position: string = '';

    gifSettings = {
        containerClassName: 'anemone-gif-source',
        angle: {
            top: false,
            right: true,
            bottom: true,
            left: false
        }
    }
    possibleGif = [
        'anemone0.gif',
        'anemone1.gif',
        'anemone2.gif',
    ];
    gifs = [];
  
    constructor(private imgService: ImgService, private scroller: Scroller,   
        private loaderService: LoaderService) {
            this.imgPath = this.imgService.setImgPath(this.type);

            this.subs.push(this.imgService.imageSize$.subscribe((data) => {
                this.position = data.format;
            }));

            this.subs.push(this.loaderService.pageLoaded$.subscribe(() => {
                let currentHours = new Date().getHours();
                if (currentHours >= 22 || currentHours < 8) {
                  $('.illustration, .text, .subhead').css('filter', 'invert(1)');
                }

                this.importantFunction();
            }));
    }

    invertIt(event) {
        let currentHours = new Date().getHours();
        if (currentHours >= 22 || currentHours < 8) {
            $(event.target).css('filter', 'invert(1)');
        }
    }

    ngOnInit() {
        this.runAllGif();
    }

    async runAllGif() {
        let random;
        let max = 8000;
        let min = 5000;
        for (let i = 0; i < 5; i++) {
            random = Math.random() * (max - min) + min;;
            await this.throwGif(random);
        }
    }

    throwGif(ms: number) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let path = '../../assets/img/gif/';
                this.gifs.push(path + this.possibleGif[Math.floor(Math.random() * this.possibleGif.length)]);
                resolve();
            }, ms);
        });
    }

    importantFunction() {
        this.imgService.setImgSize($(".homepage-img").get(0));
        this.scroller.setScrollStartY($(".homepage-img").get(0));
    }
    
    onImageResize() {
        this.imgService.setImgSize($(".homepage-img").get(0));
    }

     goToBoxOffice() {
        this.scroller.scrollToElement($(`box-office-wrapper .text-container-wrapper`));
    }

    ngOnDestroy() {
        for (let sub of this.subs) {
            sub.unsubscribe();
        }
    }
}