import { Component, OnDestroy } from '@angular/core';
import {  trigger, state, transition, style, animate} from '@angular/animations'
import { Scroller } from '../scroll-container/scroll.service';
import { Router, NavigationEnd } from '@angular/router';
import 'rxjs/add/operator/filter';

@Component({
    selector: 'menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.less'],
    animations: [
        trigger('menuMobile', [
            state('open', style({
                right: "10px",
                bottom: "10px"
            })),
            state('close', style({
                right: "85%",
                // bottom: "70%"
                // width: "50px"
            })),
            transition('open <=> close', [
                animate('0.2s')
            ])
        ])
    ]
})
export class MenuComponent implements OnDestroy{
    menuMobileOpen: boolean = false;
    subscription = [];
    url;

    menuContent = [
        { key: 1, value: "Info I"},
        { key: 2, value: "Line-up"},
        { key: 4, value: "Info II"},
        { key: 5, value: "Billeterie"},
        { key: 6, value: "Partenaires"}
    ]


    constructor(private scroller: Scroller, private router: Router) {
        this.subscription.push(this.router.events
            .filter(event => event instanceof NavigationEnd)
            .subscribe(() => {
                this.url = this.router.url;
                if (this.url.indexOf('box-office') !== -1 || this.url.indexOf('admin') !== -1) {
                    this.menuContent = [{key: 1, value: 'retournez au site'}];
                }
                else {
                    this.menuContent = [
                        { key: 1, value: "Info I"},
                        { key: 2, value: "Line-up"},
                        { key: 4, value: "Info II"},
                        { key: 5, value: "Billeterie"},
                        { key: 6, value: "Partenaires"}
                    ];
                }
            }));

    }

    toggleMenuMobile(event) {
        this.menuMobileOpen = !this.menuMobileOpen;
    }

    OnMenuClick(componentIndex) {
        if (this.url.indexOf('box-office') !== -1 || this.url.indexOf('admin') !== -1)
            this.router.navigateByUrl('/');
        else {
            this.scroller.scrollToElement($(`#${componentIndex} .text-container`));
        }
    }

    ngOnDestroy() {
        this.subscription.forEach(sub => sub.unsubscribe());
    }
}