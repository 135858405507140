import { Component } from '@angular/core';
import { LoaderService } from './loader.service';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.less']
})
export class LoaderComponent {
  allPath = [
    'anemone0.gif',
    'anemone1.gif',
    'anemone2.gif',
    'bestiole0.gif',
    'bestiole1.gif',
    'bestiole2.gif',
    'draco0.gif',
    'draco1.gif',
    'draco2.gif',
    'ovni0.gif',
    'ovni1.gif',
    'ovni2.gif',
    'poil0.gif',
    'poil1.gif',
    'poil2.gif',
    'poil3.gif',
    'scarabe0.gif',
    'scarabe1.gif',
    'scarabe2.gif',
    'scarabe3.gif',
    'sonar0.gif',
    'sonar1.gif',
    'sonar2.gif',
    'tache0.gif',
    'tache1.gif',
    'tache2.gif',
  ]

  inverted: boolean = false;

  currentPath: string = '';
  constructor() {
    this.currentPath = this.randomPath();
    
  }

  ngOnInit() {
    let currentHours = new Date().getHours();
    if (currentHours >= 22 || currentHours < 8) {
      $('.artist, .category').css('filter', 'invert(1)');
      this.inverted = true;
    }
  }

  randomPath() {
    let random = Math.floor((Math.random() * this.allPath.length))
    let path = `../../assets/img/gif/${this.allPath[random]}`;

    return path;
  }
}
