import { Directive, ElementRef, HostListener, Input, Host } from '@angular/core';
import { ImgService } from './img.service';
import { Scroller } from './scroll-container/scroll.service';
import { LoaderService } from './loader/loader.service';


@Directive({
    selector: '[backgroundImage]',
})
export class ImgDirective {
    @Input() type: string = 'default';

    constructor(private elem: ElementRef, private imgService: ImgService,
        private scroller: Scroller, private loaderService: LoaderService) {
        this.imgService.imageSize$.subscribe((property) => {
            const size = property.size;
            this.resizeImage(property.format, size.width, size.height);
        });
    }

    @HostListener('load')
    imageIsLoad() {
        this.loaderService.imgLoaded(this.type);
    }
    
    ngAfterViewInit() {
        const property = this.imgService.getImgProperty();

        this.resizeImage(property.format, property.size.width, property.size.height);
    }

    resizeImage(format, width, height) {
        const elem = this.elem.nativeElement;

        if (this.type === 'partner') {
            if (format === 'horizontal')
                height = elem.naturalHeight * $(window).width() / elem.naturalWidth;
            $('partner .component-wrapper').height(height);
            $('partner .component-wrapper .just-height').height(height);
        }

        $(elem).height(height).width(width);
    }
}