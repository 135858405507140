import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations'
import { LoaderService } from './loader/loader.service';
import { Scroller } from './scroll-container/scroll.service';
import { ImgService } from './img.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  animations: [
    trigger('loadAnim', [
      state('loaded', style({
        opacity: 1,
      })),
      state('app-not-loaded', style({
        opacity: 0,
      })),
      state('loader-not-loaded', style({
        display: 'none'
      })),
      transition('app-not-loaded => loaded', [
        animate('1s', keyframes([
          style({opacity: 0}),
          style({opacity: 1})
        ]))
      ]),
      transition('loaded => loader-not-loaded', [
        animate('1s', keyframes([
          style({opacity: 1, offset: 0}),
          style({opacity: 0, offset: 1.0})
        ]))
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit {
  title = 'Milieux';
  loaded = false;
  loadedAfterImgLoaded = false;
  loadedAfterTimer = false;
  textClass: string = '';
  letterClass: string = '';
  subs: Array<Subscription> = [];

  constructor(private loaderService: LoaderService, private scroller: Scroller,
    private imgService: ImgService) {
  }

  ngOnInit() {
    this.subs.push(this.loaderService.pageLoaded$.subscribe((bool) => {
      if (bool) {
        this.loadedAfterImgLoaded = true;
        this.itsLoad();
      } else {
        console.log('false loader');
        this.loaded = false;
      }
    }));

    this.subs.push(this.scroller.text$.subscribe((text_class) => {
      this.textClass = text_class;
      if (text_class === 'desktop') {
        $('.text-container').height(this.imgService.getImgProperty().size.height);
      }
    }));

    this.subs.push(this.scroller.letter$.subscribe((letterClass) => {
      this.letterClass = letterClass;
    }));

    this.setTimeOut(1500).then(() => {
      this.loadedAfterTimer = true;
      this.itsLoad();
  });
  }

  setTimeOut(time: number) {
    return new Promise(resolve => {
      setTimeout(resolve, time);
    })
  }

  itsLoad() {
    if (this.loadedAfterTimer && this.loadedAfterImgLoaded) {
      this.loaded = true;
    }
  }

  ngOnDestroy() {
    for (let sub of this.subs) {
      sub.unsubscribe();
    }
  }
}
