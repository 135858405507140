import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) { }

  login(password: string) {
    return this.http.post('/api/login', { password })
      .toPromise()
      .then(res => res);
  }

  logout() {
    return this.http.delete('/api/login')
      .toPromise()
      .then(res => res);
  }

  async isLogged() {
    const result = await this.http.get('/api/login').toPromise();
    return result;
  }
}
