import { Component, OnInit } from '@angular/core';
import { ImgService } from '../img.service';
import { Scroller } from '../scroll-container/scroll.service';

@Component({
  selector: 'partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.less']
})
export class PartnerComponent implements OnInit {
  type = 'partner'
  imgPath;
  textClass;

  gifSettingScarabe = {
    containerClassName: 'scarabe-gif-source',
    angle: {
        top: true,
        right: true,
        bottom: true,
        left: false,
    }
  }
  possibleGifScarabe = [
      'scarabe0.gif',
      'scarabe1.gif',
      'scarabe2.gif',
      'scarabe3.gif',
  ];
  gifsScarabe = [];

  gifSettingsTache = {
    containerClassName: 'tache-gif-source',
    angle: {
        top: true,
        right: true,
        bottom: false,
        left: true,
    }
  }
  possibleGifTache = [
      'tache0.gif',
      'tache1.gif',
      'tache2.gif',
  ];
  gifsTache = [];

  constructor(private imgService: ImgService, private scroller: Scroller) {
    this.imgPath = this.imgService.setImgPath(this.type);
    this.scroller.text$.subscribe(textClass => this.textClass = textClass);
  }

  ngOnInit() {
    this.runAllGifTache();
    this.runAllGifScarabe();


    let lastScroll = 0;
    let newScroll = 0;
    setInterval(() => {
      newScroll = $('.all-thanks').scrollTop() + 2.5;

      if (lastScroll === newScroll)
        newScroll = 0;
      lastScroll = newScroll;
      $('.all-thanks').scrollTop(newScroll);
    }, 100);
  }

  async runAllGifTache() {
      let random;
      let max = 8000;
      let min = 5000;
      for (let i = 0; i < 5; i++) {
          random = Math.random() * (max - min) + min;;
          await this.throwGifTache(random);
      }
  }

  throwGifTache(ms: number) {
      return new Promise((resolve, reject) => {
          setTimeout(() => {
              let path = '../../assets/img/gif/';
              this.gifsTache.push(path + this.possibleGifTache[Math.floor(Math.random() * this.possibleGifTache.length)]);
              resolve();
          }, ms);
      });
  }

  async runAllGifScarabe() {
    let random;
    let max = 8000;
    let min = 5000;
    for (let i = 0; i < 10; i++) {
        random = Math.random() * (max - min) + min;;
        await this.throwGifScarabe(random);
    }
  }

  throwGifScarabe(ms: number) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            let path = '../../assets/img/gif/';
            this.gifsScarabe.push(path + this.possibleGifScarabe[Math.floor(Math.random() * this.possibleGifScarabe.length)]);
            resolve();
        }, ms);
    });
  }
}
