import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import { BoxOfficeService } from 'src/app/box-office/box-office.service';
import { LoaderService } from 'src/app/loader/loader.service';

@Component({
  selector: 'admin-logged',
  templateUrl: './admin-logged.component.html',
  styleUrls: ['./admin-logged.component.less']
})
export class AdminLoggedComponent implements OnInit {

  source: LocalDataSource;
  tableSettings = {
    mode: 'external',
    actions: {
      add: false,
      edit: true,
      delete: false
    },
    edit: {
      editButtonContent: 'paypal'
    },
    columns: {
      email: {
        title: 'Email'
      },
      name: {
        title: 'Prenom'
      },
      surname: {
        title: 'Nom'
      },
      amount: {
        title: 'Prix'
      },
      createdAt: {
        title: 'Achete a'
      }
    }
  }
  totalTickets?: {nb_ticket: number | string,
                  max_ticket: number | string} = undefined;
  paypalOrderID: string = null;
  mail: string = null;

  constructor(
    private authService: AuthService,
    private router: Router,
    private boxOfficeService: BoxOfficeService,
    private loaderService: LoaderService) {
      this.loaderService.pageLoaded(true);
      this.source = new LocalDataSource();
      this.boxOfficeService.getTicketsTotal().then(result => {
        this.totalTickets = result;
      })

      this.boxOfficeService.getTickets().then(result => {
        for (let ticket of result) {
          ticket.createdAt = new Date(ticket.createdAt).toLocaleDateString();
        }
        this.source.load(result);
      });
  }

  ngOnInit() {
  }

  onClick() {
    this.authService.logout();
    this.router.navigateByUrl('/');
  }

  onTicketClick(event) {
    this.paypalOrderID = event.data.order_id;
  }

  getMail() {
    this.boxOfficeService.getMail().then((res) => {
      this.mail = res['mail'];
    });
  }
}

