import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bonus1',
  templateUrl: './bonus1.component.html',
  styleUrls: ['./bonus1.component.less']
})
export class Bonus1Component implements OnInit {
  imgPath = '../../assets/img/bonus1.jpg';

  constructor() { }

  ngOnInit() {
  }

}
